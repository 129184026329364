const tagCities = [
    'richmond',
    'vancouver',
    'burnaby',
]

const tagCuisines = [
    'japanese',
    'chinese',
    'canadian'
]

export const tagsList = [
    ...tagCities,
    ...tagCuisines
]
